import React from "react"
import Layout from "../components/layout"

// import { graphql, useStaticQuery } from "gatsby"
import "../styles/main.scss"

import mail from "../images/icons/icons8-gmail-login-96.png"
import tel from "../images/icons/icons8-telephone-96.png"
import face from "../images/icons/icons8-facebook-96.png"

const KapcsolatPage = () => {
  return (
    <Layout>
      <h1 className="text-center pt-6 pb-3">Kapcsolat</h1>
      <section id="kapcsolat">
        <div className="container vh-100">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <hr className="pb-5"></hr>

              <div className="contact-info row">
                <div className="col-md-4 col-sm-12 text-center">
                  <img
                    src={mail}
                    alt="Email cím"
                    className="img-fluid pb-3"
                  ></img>

                  <h4>EMAIL</h4>
                  <p>istvan.suli [kukac] gmail.com</p>
                </div>
                <div className="col-md-4  col-sm-12 text-center">
                  <img
                    src={tel}
                    alt="telefonszám"
                    className="img-fluid pb-3"
                  ></img>
                  <h4>TELEFON</h4>
                  <p> +36 (20) 220 7772</p>
                </div>
                <div className="col-md-4  col-sm-12 text-center">
                  <img
                    src={face}
                    alt="facebook link"
                    className="img-fluid pb-3"
                  ></img>
                  <h4>FACEBOOK</h4>
                  <a
                    href="https://www.facebook.com/istvan.suli.3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Süli István
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-2"></div>
          </div>
        </div>
      </section>

      {/* <section id="uzenet-kuldes">
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>

            <div className="col-md-8">
              <hr></hr>
              <h2 className="text-center  pt-3 pb-3">Üzenet küldés</h2>

              <form
                className="form pb-6"
                method="post"
                action="https://www.flexyform.com/f/7081a38aaee600a2b6f68c435310858b1ba6214d"
              >
                <div className="form-group">
                  <label for="name">Név:</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    aria-label="név"
                  />
                </div>
                <div className="form-group">
                  <label for="email">Email cím:</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    aria-label="email cím"
                  />
                </div>
                <div className="form-group">
                  <label for="message">Üzenet:</label>
                  <textarea
                    name="message"
                    aria-label="Üzenet"
                    rows="5"
                    className="form-control"
                  ></textarea>
                </div>

                <input
                  type="hidden"
                  name="_recaptcha"
                  id="_recaptcha"
                  aria-label="recaptcha"
                />

                <button
                  type="submit"
                  className="btn btn-primary btn-block px-3"
                  aria-label="Küldés"
                >
                  Küldés
                </button>
              </form>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </section> */}
    </Layout>
  )
}

export default KapcsolatPage
